import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../components/Layout";
import CareerStyles from "./careers.module.scss";
import "./careers.scss";
import HoCaroursel from "../components/Caroursel";
import CONSTANTS from "../constants";

function careers() {
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    async function fetchJobs() {
      try {
        const response = await axios.post(
          CONSTANTS.TURBO_HIRE_CARRER_PAGE_JOBS_URL,
          null,
          {
            headers: {
              "X-Api-Key": process.env.GATSBY_TURBO_HIRE_API_KEY,
            },
          }
        );
        if (response.status === 200) {
          setJobs(response.data.Result);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    fetchJobs();
  }, []);

  function getJobLocation(location) {
    if (location) {
      return JSON.parse(location)[0].Address;
    } else {
      return "";
    }
  }

  return (
    <Layout>
      <div className={`${CareerStyles.container}`}>
        <div className={CareerStyles.header}>
          <h1>Join the Team</h1>
          <p>
            We are a product engineering company focused on creating amazing
            digital experiences for enterprises worldwide. We’re growing rapidly
            and are always on the lookout for great talent.
          </p>
        </div>
        <div className={CareerStyles.collageWrapper}>
          <ul className={CareerStyles.collageList}>
            <li>
              <img
                data-sal="slide-right"
                data-sal-duration="500"
                data-sal-easing="easeInCubic"
                src="../../list1.png"
              ></img>
            </li>
            <li>
              <img
                data-sal="slide-down"
                data-sal-duration="500"
                data-sal-easing="easeInCubic"
                src="../../list2.png"
              ></img>
            </li>
            <li>
              <img
                data-sal="slide-down"
                data-sal-duration="500"
                data-sal-easing="easeInCubic"
                src="../../list3.png"
              ></img>
            </li>
            <li>
              <img
                data-sal="slide-down"
                data-sal-duration="500"
                data-sal-easing="easeInCubic"
                src="../../list4.png"
              ></img>
            </li>
            <li>
              <img
                data-sal="slide-left"
                data-sal-duration="500"
                data-sal-easing="easeInCubic"
                src="../../list5.png"
              ></img>
            </li>
            <li>
              <img
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-easing="easeInCubic"
                src="../../list6.png"
              ></img>
            </li>
            <li>
              <img
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-easing="easeInCubic"
                src="../../list7.png"
              ></img>
            </li>
            <li>
              <img
                data-sal="slide-left"
                data-sal-duration="500"
                data-sal-easing="easeInCubic"
                src="../../list8.png"
              ></img>
            </li>
          </ul>
        </div>
        <div className={CareerStyles.mobileCollageWrapper}>
          <ul className={CareerStyles.mobileCollageList}>
            <li>
              <img src="../../mobileList1.png"></img>
            </li>
            <li>
              <img src="../../mobileList2.png"></img>
            </li>
            <li>
              <img src="../../mobileList3.png"></img>
            </li>
            <li>
              <img src="../../mobileList4.png"></img>
            </li>
            <li>
              <img src="../../mobileList5.png"></img>
            </li>
          </ul>
        </div>
        <div className={CareerStyles.positions}>
          <h2>Explore our open positions</h2>
          <div className={CareerStyles.positionWrapper}>
            <ul
            // data-sal="slide-up"
            // data-sal-duration="500"
            // data-sal-delay="300"
            // data-sal-easing="easeInCubic"
            >
              {jobs.map((job) => (
                <li key={job.JobId}>
                  <h4 className={CareerStyles.positionsTitile}>
                    <a
                      href={job.ApplyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {job.JobTitle}
                    </a>
                  </h4>
                  <div className={CareerStyles.descriptionWrapper}>
                    <p dangerouslySetInnerHTML={{ __html: job.JobDescription }} />
                    <p>{getJobLocation(job.Location)}</p>
                  </div>
                </li>
              ))}
            </ul>
            <a
              href="https://hashouttech.turbohire.co/dashboard"
              target="_blank"
              rel="noopener noreferrer"
            >
              Show All Openings
              <span>
                <img src="../../RedVector.svg"></img>
              </span>
            </a>
          </div>
        </div>
        <div className={`${CareerStyles.offerCarousel}`}>
          <div className={CareerStyles.careerHeader}>
            <div
              data-sal="slide-right"
              data-sal-duration="500"
              data-sal-delay="400"
              data-sal-easing="easeInCubic"
            >
              <h2>What does a career at Hashout offer you?</h2>
              <p>
                We pride ourselves on hiring the best and empowering them to
                fulfill their potential. This means giving each team member full
                ownership of their work and the resources they need to
                accomplish their goals.
              </p>
            </div>
            <div></div>
          </div>
          <div className={CareerStyles.careerBody}>
            <ul className={CareerStyles.carrerCardWrapper}>
              <li
                data-sal="slide-right"
                data-sal-duration="500"
                data-sal-delay="300"
                data-sal-easing="easeInCubic"
                className={CareerStyles.carrerCard}
              >
                <img
                  className={CareerStyles.offerImage}
                  src="../../learning-of-life-time.png"
                ></img>
                <p className={CareerStyles.offerTitle}>Learning of lifetime</p>
                <p className={CareerStyles.offerDescription}>
                  At the core of our delivery model is our product mindset. Our
                  teams constantly expand the boundaries to deliver more with
                  less. With our continuous focus on learning, no two year will
                  be the same for you.
                </p>
              </li>
              <li
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-delay="300"
                data-sal-easing="easeInCubic"
                className={CareerStyles.carrerCard}
              >
                <img
                  className={CareerStyles.offerImage}
                  src="../../work-with-best.png"
                ></img>
                <p className={CareerStyles.offerTitle}>
                  Work with the best and the latest
                </p>
                <p className={CareerStyles.offerDescription}>
                  Our carefully chosen technology stack and partnerships keep us
                  right at the intersection between cutting edge technology and
                  demand. At Hashout labs, we constantly push the capabilities
                  of our partner platforms. Come and pioneer the change with us!
                </p>
              </li>
              <li
                data-sal="slide-left"
                data-sal-duration="500"
                data-sal-delay="300"
                data-sal-easing="easeInCubic"
                className={CareerStyles.carrerCard}
              >
                <img
                  className={CareerStyles.offerImage}
                  src="../../work-with-fortune.png"
                ></img>
                <p className={CareerStyles.offerTitle}>
                  Work with fortune 500 clients
                </p>
                <p className={CareerStyles.offerDescription}>
                  We have successfully delivered customer experience platforms
                  for top brands across technology, healthcare, logistics,
                  retail and financial services industries. With us - You will
                  shape some of the most impactful business and mission critical
                  experiences for global enterprises.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className={CareerStyles.albumWrapper}>
          <div className={CareerStyles.albumContainer}>
            <div className={CareerStyles.albumCard}>
              <img src="../../list5.svg"></img>
              <p>Ex nostrud cillum dolor adipisicing elit non.</p>
            </div>
            <div className={CareerStyles.albumCard}>
              <img src="../../list5.svg"></img>
              <p>Ex nostrud cillum dolor adipisicing elit non.</p>
            </div>
            <div className={CareerStyles.albumCard}>
              <img src="../../list5.svg"></img>
              <p>Ex nostrud cillum dolor adipisicing elit non.</p>
            </div>
            <div className={CareerStyles.albumCard}>
              <img src="../../list5.svg"></img>
              <p>Ex nostrud cillum dolor adipisicing elit non.</p>
            </div>
            <div className={CareerStyles.albumCard}>
              <img src="../../list5.svg"></img>
              <p>Ex nostrud cillum dolor adipisicing elit non.</p>
            </div>
            <div className={CareerStyles.albumCard}>
              <img src="../../list5.svg"></img>
              <p>Ex nostrud cillum dolor adipisicing elit non.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${CareerStyles.benifitsSection}`}>
        <div
          // data-sal="slide-up"
          // data-sal-duration="500"
          // data-sal-delay="300"
          // data-sal-easing="easeInCubic"
          className={CareerStyles.benifitHeader}
        >
          <h2>Benefits and Perks</h2>
          <p>
            We offer the best packages in the industry topped with an
            environment to learn and grow.
          </p>
        </div>
        <div className={CareerStyles.benifitsOptionsWrapper}>
          <ul className={CareerStyles.benifitContainer}>
            <li
              // data-sal="slide-left"
              // data-sal-duration="500"
              // data-sal-delay="400"
              // data-sal-easing="easeInCubic"
              className={CareerStyles.benifitCard}
            >
              <img src="../../employeeFirst.svg"></img>
              <p className={CareerStyles.benifitTitle}>Employee First</p>
              <p className={CareerStyles.benifitDetails}>
                We believe in freedom and accountability. All our policies are
                employee friendly
              </p>
            </li>
            <li
              // data-sal="slide-right"
              // data-sal-duration="500"
              // data-sal-delay="400"
              // data-sal-easing="easeInCubic"
              className={CareerStyles.benifitCard}
            >
              <img src="../../inclusiveAndDiverse.svg"></img>
              <p className={CareerStyles.benifitTitle}>Inclusive and Diverse</p>
              <p className={CareerStyles.benifitDetails}>
                We are an equal opportunity provider and proud to be a
                discrimination free and inclusive organisation
              </p>
            </li>
            <li
              // data-sal="slide-right"
              // data-sal-duration="500"
              // data-sal-delay="400"
              // data-sal-easing="easeInCubic"
              className={CareerStyles.benifitCard}
            >
              <img src="../../funPlaceToWork.svg"></img>
              <p className={CareerStyles.benifitTitle}>Fun place to work</p>
              <p className={CareerStyles.benifitDetails}>
                Work hard, party hard and share passions together with regular
                onsite, virtual and offsite pit stops.
              </p>
            </li>
            <li
              // data-sal="slide-left"
              // data-sal-duration="500"
              // data-sal-delay="400"
              // data-sal-easing="easeInCubic"
              className={CareerStyles.benifitCard}
            >
              <img src="../../group-health-insurance.svg"></img>
              <p className={CareerStyles.benifitTitle}>
                Group Health Insurance
              </p>
              <p className={CareerStyles.benifitDetails}>
                Medical Insurance also covers Spouse and 2 children.
              </p>
            </li>
          </ul>
          <ul className={CareerStyles.benifitContainer}>
            <li
              // data-sal="slide-right"
              // data-sal-duration="500"
              // data-sal-delay="400"
              // data-sal-easing="easeInCubic"
              className={CareerStyles.benifitCard}
            >
              <img src="../../meritocracy.svg"></img>
              <p className={CareerStyles.benifitTitle}>Meritocracy</p>
              <p className={CareerStyles.benifitDetails}>
                We reward transparently! Your work speaks for you!
              </p>
            </li>
            <li
              // data-sal="slide-left"
              // data-sal-duration="500"
              // data-sal-delay="400"
              // data-sal-easing="easeInCubic"
              className={CareerStyles.benifitCard}
            >
              <img src="../../bringYourOwnDevice.svg"></img>
              <p className={CareerStyles.benifitTitle}>Bring Your Own Device</p>
              <p className={CareerStyles.benifitDetails}>
                Choose a device of your choice or bring your favorite device
                onboard and opt in for support.
              </p>
            </li>
            <li
              // data-sal="slide-left"
              // data-sal-duration="500"
              // data-sal-delay="400"
              // data-sal-easing="easeInCubic"
              className={CareerStyles.benifitCard}
            >
              <img src="../../earned-leaves.svg"></img>
              <p className={CareerStyles.benifitTitle}>Earned Leaves</p>
              <p className={CareerStyles.benifitDetails}>
                We work 5 days a week. You will receive 22 paid holidays each
                calendar year on a pro-rata basis, along with public holidays.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`${CareerStyles.container} ${CareerStyles.teamCommentsSection}`}
      >
        <h2>Read what our team has to say!</h2>
        <div
          className={`${CareerStyles.commentCarousel} commentCarouselWrapper`}
        >
          <HoCaroursel
            showThumbs={false}
            showArrows={true}
            showIndicators={true}
            swipeable={true}
            infiniteLoop={false}
            statusFormatter={(current, total) => `0${current}/0${total}`}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title="Previous Slide"
                  className="control-arrow control-arrow2 prev-control2"
                >
                  <img
                    className="prev-control-image"
                    src="../../carouselNext.svg"
                  ></img>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title="Next Slide"
                  className="control-arrow control-arrow-2 next-control2"
                >
                  <img
                    className="next-control-image"
                    src="../../carouselNext.svg"
                  ></img>
                </button>
              )
            }
          >
            <div className={CareerStyles.carouselCard}>
              <div className={CareerStyles.cardImageWrapper}>
                <img src="../../Priyanka.png"></img>
              </div>
              <div className={CareerStyles.commentsWrapper}>
                <div className={CareerStyles.quote}>
                  <img src="../../quote.svg"></img>
                </div>
                <div className={CareerStyles.comments}>
                  <p>
                    Hashout is an all inclusive and fun place to work. From
                    company offsites to Online Yoga classes - not a day is dull
                    here!
                  </p>
                  <p>Priyanka</p>
                  <p>Bangalore</p>
                </div>
              </div>
            </div>
            <div className={CareerStyles.carouselCard}>
              <div className={CareerStyles.cardImageWrapper}>
                <img src="../../Akshay.png"></img>
              </div>
              <div className={CareerStyles.commentsWrapper}>
                <div className={CareerStyles.quote}>
                  <img src="../../quote.svg"></img>
                </div>
                <div className={CareerStyles.comments}>
                  <p>
                    Working in Hashout is simply fun and free with subtle
                    professionalism. From the first moment in the company,
                    anyone would feel right at home, gaining friends for a
                    lifetime.
                  </p>
                  <p>Akshay Rathnavas</p>
                  <p>Bangalore</p>
                </div>
              </div>
            </div>
            <div className={CareerStyles.carouselCard}>
              <div className={CareerStyles.cardImageWrapper}>
                <img src="../../Nilabjo.png"></img>
              </div>
              <div className={CareerStyles.commentsWrapper}>
                <div className={CareerStyles.quote}>
                  <img src="../../quote.svg"></img>
                </div>
                <div className={CareerStyles.comments}>
                  <p>
                    Hashout is a cool place to work at. I get to contribute on
                    interesting projects and work with talented and nice
                    colleagues.
                  </p>
                  <p>Nilabjo Sanyal</p>
                  <p>Bangalore</p>
                </div>
              </div>
            </div>
          </HoCaroursel>
        </div>
      </div>
    </Layout>
  );
}

export default careers;
